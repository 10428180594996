import React, { useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function App() {
  const [text, setText] = useState('');

  const extractText = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const typedArray = new Uint8Array(e.target.result);
      const pdf = await pdfjsLib.getDocument(typedArray).promise;
      let fullText = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const content = await page.getTextContent();
        const strings = content.items.map(item => item.str);
        fullText += strings.join(' ') + '\n';
      }
      setText(fullText);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="App">
      <h1>PDF Text Extractor</h1>
      <input type="file" onChange={(e) => extractText(e.target.files[0])} accept=".pdf" />
      <pre>{text}</pre>
    </div>
  );
}

export default App;


